import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	HeaderSection,
	ContentSection,
	Code,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { Paragraph } from "../paragraph/paragraph";
import { Ul, Ol } from "../list/list";
import { ExternalHtml } from "../external-html/external-html";
import { H1, H2, H3, H4, H5, H6 } from "../heading/heading";
import { TextContainer } from "./text-container";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Text container",
	notepad: "https://hackmd.io/8RrUPTvPTVKPa6DoC2GlCg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Text container"
			subTitle="Use this component to add vertical spacing between headings, paragraphs, and lists. You can also use it to optimize the reading experience of long-form content."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={TextContainer} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Our block-level typography components (<GatsbyLink to="../Heading">heading</GatsbyLink>,{" "}
					<GatsbyLink to="../Paragraph">paragraph</GatsbyLink>, and{" "}
					<GatsbyLink to="../List">list</GatsbyLink>) don't have any margin by default. This is
					intentional and desires in most UI contexts. However, it also means multiple consecutive
					typography components will stick together. That is where the text container component
					comes in. By wrapping the typograhy components in a text-container, approporaite vertical
					spacing will automatically be applied between the components.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					Here you can see an example of how the text container with default property settings
					automatically applies appropriate vertical spacing between consecutive typographic
					components.
				</Paragraph>
				<Example fn={basicUsage} />
				<Header.H3>Article</Header.H3>
				<Paragraph>
					Some pages, such as product teaser pages, feature a large number of consecutive
					typographic components. This is called long-form content. The text container component has
					an <Code>article</Code> property to optimize the reading experience of such content. This
					property increases the font-size and adjusts the spacing, font-weights and line-heights of
					the typographic components inside the text-container.
				</Paragraph>
				<Example fn={articleUsage} />
				<Header.H3>External HTML</Header.H3>
				<Paragraph>
					As you can see in the example below, the text container component also works in
					combination with our <GatsbyLink to="../External html">external html</GatsbyLink>{" "}
					component.
				</Paragraph>
				<Example fn={externalhtmlUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={TextContainer}
					initialProps={{
						children: (
							<>
								<H1>Heading 1</H1>
								<H2>Heading 2</H2>
								<Paragraph>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus erat rhoncus
									bibendum cursus. Maecenas a lorem egestas lorem porttitor scelerisque vel ac ante.
									Pellentesque ac ullamcorper libero. Cras consectetur sem nunc, nec rhoncus justo
									porta quis.Mauris sapien tellus, mattis ac pharetra et, accumsan pharetra felis.
								</Paragraph>
								<Paragraph>
									Ut cursus condimentum urna, vel pellentesque mauris iaculis nec. Proin ac mauris
									est. Suspendisse mollis rutrum dui, mattis cursus nunc dictum a. Pellentesque
									habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
									Lorem ipsum dolor sit amet, consectetur adipiscing elit.
								</Paragraph>
								<H3>Heading 3</H3>
								<Ul
									items={[
										"Ut et varius justo, id molestie massa.",
										"Cras mattis urna at sapien tempor, ac hendrerit neque dignissim",
										"Sed iaculis leo vitae nunc auctor maximus.",
									]}
								/>
								<H4>Heading 4</H4>
								<Ol
									items={[
										"Integer sodales vulputate pellentesque.",
										"Pellentesque hendrerit et nisi quis aliquet.",
										"Mauris sapien tellus, mattis ac pharetra et.",
									]}
								/>
								<H5>Heading 5</H5>
								<Paragraph>
									Fusce quis nulla ut odio hendrerit commodo. Nunc sed cursus erat. Nam ullamcorper
									sem est, at molestie justo egestas sed. Etiam lectus quam, facilisis sed ipsum ut,
									tincidunt pretium ex. Nam at condimentum eros. Cras mattis urna at sapien tempor,
									ac hendrerit neque dignissim. Nunc sed elementum purus, ac pulvinar orci. Vivamus
									lacinia elementum lorem, quis tincidunt diam. Suspendisse semper lobortis quam,
									eget semper elit tempus ac.
								</Paragraph>
								<H6>Heading 6</H6>
								<Paragraph>
									Donec condimentum, mi tincidunt mollis consectetur, arcu leo porttitor velit,
									ultrices accumsan ante odio at dui. Sed eget laoreet lorem. Morbi imperdiet quam
									et suscipit efficitur. Fusce orci tortor, bibendum et tempus et, consectetur
									bibendum ante. Curabitur nulla risus, vestibulum in laoreet vitae, consectetur
									pharetra ex. Curabitur porttitor placerat metus in aliquet. Nunc scelerisque quam
									est, feugiat laoreet dui mattis semper.
								</Paragraph>
							</>
						),
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							<>
								Use when you have two or more consecutive{" "}
								<GatsbyLink to="../Paragraph">paragraphs</GatsbyLink>,{" "}
								<GatsbyLink to="../Heading">headings</GatsbyLink>, or{" "}
								<GatsbyLink to="../List">lists</GatsbyLink>.
							</>,
							<>
								Use the "article" property when you have a large number of consecutive typographic
								components.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						You want to add a space around a single typographic component. Use a{" "}
						<GatsbyLink to="/lib/components/Structure/Content">content</GatsbyLink> component
						instead.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required. However, you
						should make sure to read the accessibility guidelines for the typography components used
						inside text containers, such as <GatsbyLink to="../Paragraph">paragraphs</GatsbyLink>,{" "}
						<GatsbyLink to="../Heading">headings</GatsbyLink>, or{" "}
						<GatsbyLink to="../List">lists</GatsbyLink>.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>
						There are no writing guidelines specifically for this component. However, make sure to
						read the writing guidelines for the typography components used inside text containers,
						such as <GatsbyLink to="../Paragraph">paragraphs</GatsbyLink>,{" "}
						<GatsbyLink to="../Heading">headings</GatsbyLink>, or{" "}
						<GatsbyLink to="../List">lists</GatsbyLink>.
					</Paragraph>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const basicUsage = () => (
	<TextContainer>
		<H1>Heading 1</H1>
		<H2>Heading 2</H2>
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus erat rhoncus bibendum
			cursus. Maecenas a lorem egestas lorem porttitor scelerisque vel ac ante. Pellentesque ac
			ullamcorper libero. Cras consectetur sem nunc, nec rhoncus justo porta quis.Mauris sapien
			tellus, mattis ac pharetra et, accumsan pharetra felis.
		</Paragraph>
		<Paragraph>
			Ut cursus condimentum urna, vel pellentesque mauris iaculis nec. Proin ac mauris est.
			Suspendisse mollis rutrum dui, mattis cursus nunc dictum a. Pellentesque habitant morbi
			tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet,
			consectetur adipiscing elit.
		</Paragraph>
		<H3>Heading 3</H3>
		<Ul
			items={[
				"Ut et varius justo, id molestie massa.",
				"Cras mattis urna at sapien tempor, ac hendrerit neque dignissim",
				"Sed iaculis leo vitae nunc auctor maximus.",
			]}
		/>
		<H4>Heading 4</H4>
		<Ol
			items={[
				"Integer sodales vulputate pellentesque.",
				"Pellentesque hendrerit et nisi quis aliquet.",
				"Mauris sapien tellus, mattis ac pharetra et.",
			]}
		/>
		<H5>Heading 5</H5>
		<Paragraph>
			Fusce quis nulla ut odio hendrerit commodo. Nunc sed cursus erat. Nam ullamcorper sem est, at
			molestie justo egestas sed. Etiam lectus quam, facilisis sed ipsum ut, tincidunt pretium ex.
			Nam at condimentum eros. Cras mattis urna at sapien tempor, ac hendrerit neque dignissim. Nunc
			sed elementum purus, ac pulvinar orci. Vivamus lacinia elementum lorem, quis tincidunt diam.
			Suspendisse semper lobortis quam, eget semper elit tempus ac.
		</Paragraph>
		<H6>Heading 6</H6>
		<Paragraph>
			Donec condimentum, mi tincidunt mollis consectetur, arcu leo porttitor velit, ultrices
			accumsan ante odio at dui. Sed eget laoreet lorem. Morbi imperdiet quam et suscipit efficitur.
			Fusce orci tortor, bibendum et tempus et, consectetur bibendum ante. Curabitur nulla risus,
			vestibulum in laoreet vitae, consectetur pharetra ex. Curabitur porttitor placerat metus in
			aliquet. Nunc scelerisque quam est, feugiat laoreet dui mattis semper.
		</Paragraph>
	</TextContainer>
);

const articleUsage = () => (
	<TextContainer article>
		<H1>Heading 1</H1>
		<H2>Heading 2</H2>
		<Paragraph>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus erat rhoncus bibendum
			cursus. Maecenas a lorem egestas lorem porttitor scelerisque vel ac ante. Pellentesque ac
			ullamcorper libero. Cras consectetur sem nunc, nec rhoncus justo porta quis.Mauris sapien
			tellus, mattis ac pharetra et, accumsan pharetra felis.
		</Paragraph>
		<Paragraph>
			Ut cursus condimentum urna, vel pellentesque mauris iaculis nec. Proin ac mauris est.
			Suspendisse mollis rutrum dui, mattis cursus nunc dictum a. Pellentesque habitant morbi
			tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet,
			consectetur adipiscing elit.
		</Paragraph>
		<H3>Heading 3</H3>
		<Ul
			items={[
				"Ut et varius justo, id molestie massa.",
				"Cras mattis urna at sapien tempor, ac hendrerit neque dignissim",
				"Sed iaculis leo vitae nunc auctor maximus.",
			]}
		/>
		<H4>Heading 4</H4>
		<Ol
			items={[
				"Integer sodales vulputate pellentesque.",
				"Pellentesque hendrerit et nisi quis aliquet.",
				"Mauris sapien tellus, mattis ac pharetra et.",
			]}
		/>
		<H5>Heading 5</H5>
		<Paragraph>
			Fusce quis nulla ut odio hendrerit commodo. Nunc sed cursus erat. Nam ullamcorper sem est, at
			molestie justo egestas sed. Etiam lectus quam, facilisis sed ipsum ut, tincidunt pretium ex.
			Nam at condimentum eros. Cras mattis urna at sapien tempor, ac hendrerit neque dignissim. Nunc
			sed elementum purus, ac pulvinar orci. Vivamus lacinia elementum lorem, quis tincidunt diam.
			Suspendisse semper lobortis quam, eget semper elit tempus ac.
		</Paragraph>
		<H6>Heading 6</H6>
		<Paragraph>
			Donec condimentum, mi tincidunt mollis consectetur, arcu leo porttitor velit, ultrices
			accumsan ante odio at dui. Sed eget laoreet lorem. Morbi imperdiet quam et suscipit efficitur.
			Fusce orci tortor, bibendum et tempus et, consectetur bibendum ante. Curabitur nulla risus,
			vestibulum in laoreet vitae, consectetur pharetra ex. Curabitur porttitor placerat metus in
			aliquet. Nunc scelerisque quam est, feugiat laoreet dui mattis semper.
		</Paragraph>
	</TextContainer>
);
const externalhtmlUsage = () => (
	<TextContainer>
		<ExternalHtml>
			{`<h1>Heading 1</h1>
			<h2>Heading 2</h2>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tempus erat rhoncus bibendum
				cursus. Maecenas a lorem egestas lorem porttitor scelerisque vel ac ante. Pellentesque ac
				ullamcorper libero. Cras consectetur sem nunc, nec rhoncus justo porta quis.Mauris sapien
				tellus, mattis ac pharetra et, accumsan pharetra felis.
			</p>
			<p>
				Ut cursus condimentum urna, vel pellentesque mauris iaculis nec. Proin ac mauris est.
				Suspendisse mollis rutrum dui, mattis cursus nunc dictum a. Pellentesque habitant morbi
				tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit
				amet, consectetur adipiscing elit.
			</p>
			<h3>Heading 3</h3>
			<ul>
				<li>Ut et varius justo, id molestie massa.</li>
				<li>Cras mattis urna at sapien tempor, ac hendrerit neque dignissim</li>
				<li>Sed iaculis leo vitae nunc auctor maximus.</li>
			</ul>
			<h4>Heading 4</h4>
			<ol>
				<li>Integer sodales vulputate pellentesque.</li>
				<li>Pellentesque hendrerit et nisi quis aliquet.</li>
				<li>Mauris sapien tellus, mattis ac pharetra et.</li>
			</ol>
			<h5>Heading 5</h5>
			<p>
				Fusce quis nulla ut odio hendrerit commodo. Nunc sed cursus erat. Nam ullamcorper sem est,
				at molestie justo egestas sed. Etiam lectus quam, facilisis sed ipsum ut, tincidunt pretium
				ex. Nam at condimentum eros. Cras mattis urna at sapien tempor, ac hendrerit neque
				dignissim. Nunc sed elementum purus, ac pulvinar orci. Vivamus lacinia elementum lorem, quis
				tincidunt diam. Suspendisse semper lobortis quam, eget semper elit tempus ac.
			</p>
			<h6>Heading 6</h6>
			<p>
				Donec condimentum, mi tincidunt mollis consectetur, arcu leo porttitor velit, ultrices
				accumsan ante odio at dui. Sed eget laoreet lorem. Morbi imperdiet quam et suscipit
				efficitur. Fusce orci tortor, bibendum et tempus et, consectetur bibendum ante. Curabitur
				nulla risus, vestibulum in laoreet vitae, consectetur pharetra ex. Curabitur porttitor
				placerat metus in aliquet. Nunc scelerisque quam est, feugiat laoreet dui mattis semper.
			</p>`}
		</ExternalHtml>
	</TextContainer>
);
