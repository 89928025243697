import React from "react";
import { cn } from "../../../utils/shorthands";
import {
	DataObserveKey,
	DataComponent,
	VisualComponent,
} from "../../../types/fancy-base-attributes";
import * as scss from "./external-html.scss";

type ExternalHtmlProps = {
	/** HTML string that is rendered with the styling of fancy typography components */
	children: string;
} & DataObserveKey &
	DataComponent &
	VisualComponent;

export function ExternalHtml(props: ExternalHtmlProps): JSX.Element {
	const { children, className, style } = props;

	return (
		<div
			data-observe-key={props["data-observe-key"]}
			data-component={props["data-component"] || "external-html"}
			className={cn(scss.externalHtml, className)}
			style={style}
			dangerouslySetInnerHTML={{ __html: children }}
		/>
	);
}
